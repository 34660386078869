import Page from 'attendee/theme/Page'
import React from 'react'
import {EventListItem} from 'attendee/EventListItem'
import {useAttendeeEventsList} from 'attendee/providers/AttendeeEventsListProvider'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import CounterShape from 'assets/img/shape/counter-shape.png'
import {routes} from 'routes/routes'
import home1 from 'assets/custom_images/home1.png'
import home2 from 'assets/custom_images/home2.png'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import {WhyOnlineEvent} from 'attendee/theme/WhyOnlineEvent'
import {Sticks} from 'attendee/Sticks'
import { Helmet } from 'react-helmet'

export function Index() {
  const {events} = useAttendeeEventsList()
  const {user} = useAuth()

  return (
    <Page>
      <>

      <Helmet> 
        <title>Eventa.tv - Платформа за организиране на онлайн събития насочени към малкия и среден бизнес | Виртуални събития</title> 
        <meta name="description" content="Платформа за организиране на онлайн събития насочени към малкия и среден бизнес | Виртуални събития" /> 
      </Helmet>
      
        <PromotionHolder>
          <div>
            Промоционална цена за създаване на събитие от{' '}
            <span style={{textDecoration: 'line-through'}}>179</span> лв. на{' '}
            <b style={{fontSize: '1.5rem'}}>1 лв.</b>
          </div>
          <Link to={routes.organization.login}> Регистрирай се сега </Link>
        </PromotionHolder>

        <section className="event__schedule-area dark_light_class pt-55 pb-35 ">
          <div className="container">
            <div className="section__title-wrapper is-left mb-50">
              <span className="section__back-title">СС</span>
              <span className="section__subtitle">График на събитията</span>
              <h2 className="section__title">
                Списък на <span className="text__highlight">Събитията</span>
              </h2>
            </div>

            <div className="row">
              {events.length > 0 &&
                events.map((event, index) => (
                  <EventListItem event={event} index={index} key={index} />
                ))}
            </div>
          </div>
        </section>

        <section className="event__schedule-area dark_light_class pt-55 pb-35 ">
          <div className="container">
            <div className="section__title-wrapper is-left mb-50">
              <span className="section__back-title">СЕ</span>
              <span className="section__subtitle">Направи си сам</span>
              <h2 className="section__title">
                Стикове с <span className="text__highlight">Емотикони</span>
              </h2>
            </div>

            <div className="row">
              <Sticks />
            </div>
          </div>
        </section>

        <section className="counter__area include__bg pt-150 pb-120 counter__overlay fix">
          <div className="counter__shape">
            <img
              src={CounterShape}
              style={{width: 'auto', height: 'auto'}}
              alt="image not found"
            />
          </div>
          <div className="container">
            <div className="row" style={{alignItems: 'center'}}>
              <HelpText>Нужна ти е помощ?</HelpText>

              <HelpButtonHolder>
                <HelpButton className="input__btn" to={routes.info}>
                  <TipsAndUpdatesIcon /> <span>Какво е Eventa.tv</span>
                </HelpButton>
                <HelpButton className="input__btn" to={routes.help}>
                  <LiveHelpIcon /> <span>Помощ</span>
                </HelpButton>
              </HelpButtonHolder>
            </div>
          </div>
        </section>

        <section
          id="homeabout"
          className={`about__area p-relative pt-150 dark_light_class `}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="about__content-wrapper mb-60">
                  <div className="section__title-wrapper mb-80">
                    <span className="section__back-title">ОС</span>
                    <span className="section__subtitle">Онлайн събития</span>
                    <h2 className="section__title">
                      Изживей едно истинско цифрово{' '}
                      <span className="text__highlight">преживяване</span>
                    </h2>
                  </div>
                  <div className="about__content">
                    <div className="about__text">
                      <h3
                        style={{
                          fontSize: 'var(--bd-fs-h4)',
                          color: 'var(--clr-text-1)',
                        }}
                      >
                        Впусни се в динамичния и вълнуващ свят на съвременните
                        технологии.
                      </h3>
                      <p className="mb-30">
                        {' '}
                        Интерактивната платформа предлага персонализирано и
                        ангажиращо изживяване, което може да трансформира
                        начина, по който учиш, работиш и се забавляваш.
                      </p>
                      <p>
                        Регистрирай се, за да бъдеш сред първите, които ще
                        получат достъп до новите събития!
                      </p>
                    </div>
                    <div className="about__location-info mb-80">
                      <div className="about__location-inner">
                        <div className="about__location-icon">
                          <Link to="#" aria-label="nowhere">
                            <i className="fa-solid fa-location-dot"></i>
                          </Link>
                        </div>
                        <div className="about__location-address">
                          <h4>Къде и кога</h4>
                          <span>
                            На всякъде <br /> и по всяко време
                          </span>
                        </div>
                      </div>
                      <div className="pluse__status">
                        <span className="dot"></span>
                        <span className="text">Онлайн</span>
                      </div>
                    </div>
                  </div>
                  {user && (
                    <Link to={routes.info} className="fill__btn">
                      Научи повече<i className="fa-regular fa-angle-right"></i>
                    </Link>
                  )}
                  {!user && (
                    <Link to={routes.register} className="fill__btn">
                      Регистирай се сега
                      <i className="fa-regular fa-angle-right"></i>
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="about__thumb-wrapper mb-60 p-relative">
                  <div className="about__thumb-1 text-end w-img">
                    <img
                      src={home2}
                      style={{width: '100%', height: 'auto'}}
                      alt="image not found"
                    />
                    <div className="panel wow"></div>
                  </div>
                  <div className="about__thumb-2 w-img">
                    <img
                      src={home1}
                      style={{width: '100%', height: 'auto'}}
                      alt="image not found"
                    />
                    <div className="panel wow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <WhyOnlineEvent />

        <section className="counter__area include__bg pt-150 pb-120 counter__overlay fix">
          <div className="counter__shape">
            <img
              src={CounterShape}
              style={{width: 'auto', height: 'auto'}}
              alt="image not found"
            />
          </div>
          <div className="container">
            <div className="row" style={{alignItems: 'center'}}>
              <HelpText>Искаш собствено събитие?</HelpText>

              <HelpButtonHolder>
                <HelpButton
                  className="input__btn"
                  to={routes.organization.login}
                >
                  <CorporateFareIcon /> <span>Регистрация на организация</span>
                </HelpButton>
              </HelpButtonHolder>
            </div>
          </div>
        </section>

        {/* <div className="partner__area grey-bg pt-100 pb-100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="section__title-wrapper is-left mb-110">
                                <span className="section__back-title">НП</span>
                                <span className="section__subtitle">Със съдействието на</span>
                                <h2 className="section__title">
                                    Нашите <span className="text__highlight"> партньори
                                    </span>
                                </h2>
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="partner__grid-wrapper">
                                {brand_data.length > 0 &&
                                    brand_data.map((item, index) => (
                                        <div key={index} className="partner__item">
                                            <div className="partner__thumb">
                                                <Link to="#">
                                                    <img src={item.thubmnail} style={{ width: "auto", height: "auto" }} alt="image not found" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </>
    </Page>
  )
}

export const HelpText = styled.div`
  color: white;
  font-size: 3rem;
  line-height: 3rem;

  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
  }
`

export const HelpButtonHolder = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 35px;
  }
`

export const HelpButton = styled(Link)`
  width: 100%;
  background: #ffffff;
  color: #f7757a;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 5px;

  & > svg {
    margin-right: auto;
  }

  & > span {
    margin-right: auto;
  }

  &:hover {
    background: var(--clr-gradient-14);
    color: #ffffff;
  }

  @media (max-width: 440px) {
    & > span {
      font-size: 1.1rem;
    }
  }
`

export const PromotionHolder = styled.div`
  background: #f7426f;
  color: white;
  width: 100%;
  padding: 15px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  flex-direction: column;

  & > a {
    background: white;
    border-radius: 6px;
    padding: 5px 15px;
    margin-top: 5px;
    display: block;
    max-width: 250px;
  }
`
