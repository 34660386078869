import React, {useLayoutEffect} from 'react'
import {BrowserRouter, useLocation} from 'react-router-dom'
import {AllRoutes} from './routes/routes'
import 'assets/styles.scss'
import {ToastContainer} from 'react-toastify'
import {ElementsProvider} from 'stripe/ElementsProvider'

if (typeof window !== 'undefined') {
  require('bootstrap/dist/js/bootstrap')
}

const Wrapper = ({children}: any) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <ElementsProvider>
            <AllRoutes />
          </ElementsProvider> 
        </Wrapper>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export default App
