import {useEventEarning} from 'organization/Event/Earning/EventEarningProvider'
import {ListBalance} from 'organization/Event/Earning/ListBalance'
import EventPage from 'organization/Event/EventPage'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Pagination} from '@mui/material'
import {useStripeConnection} from 'organization/Event/Earning/StripeConnectionProvider'
import {confirmAlert} from 'react-confirm-alert'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import {useNavigate} from 'react-router'
import {useOrganizationEventRoutes} from 'organization/OrganizationRoutes'

export const Earning = () => {
  const {
    totalProfit,
    ticketSales,
    platformTax,
    balance,
    totalPages,
    page,
    changePage,
  } = useEventEarning()

  const navigate = useNavigate()
  const {event} = useOrganizationEvent()
  const routes = useOrganizationEventRoutes()

  useEffect(() => {
    if (event?.is_free) {
      navigate(routes.root)
    }
  }, [event?.is_free, navigate, routes.root])

  const [stripeEmail, setStripeEmail] = useState<string>('')

  const {
    addConnection,
    removeConnection,
    getDashboard,
    stripeConnection,
    loading,
  } = useStripeConnection()

  const hanldeAddConnection = () => {
    addConnection(stripeEmail)
  }

  const handleRemoveConnection = () => {
    confirmAlert({
      message: 'Сигурни ли сте, че искате да прекъснете връзката?',
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Да',
          onClick: async () => {
            removeConnection()
          },
        },
        {
          label: 'Не',
          onClick: () => {
            // nothing
          },
        },
      ],
    })
  }

  const handleDashboard = () => {
    getDashboard()
  }

  return (
    <EventPage>
      <>
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Печалба" />

          <div className="create__event-area mb-20 col-12">
            <div className="body__card-wrapper attendant__wrapper">
              <div className="row">
                <div className="col-6">
                  <p>
                    <b>Stripe акаунт</b>
                  </p>

                  {stripeConnection?.completed_setup && (
                    <div>
                      Stripe мейл: {stripeConnection.email} <br />
                      Stripe номер: {stripeConnection.stripe_account_id} <br />
                      Валута: {stripeConnection.default_currency} <br />
                      Държава: {stripeConnection.country} <br />
                      Плащания активни:{' '}
                      {stripeConnection.charges_enabled ? (
                        'Да'
                      ) : (
                        <span style={{color: 'red'}}>
                          Не можете да получавате средства{' '}
                        </span>
                      )}{' '}
                      <br />
                      <b>
                        Печалбата ви ще бъде изплатена 3 дена след края на събитието.
                      </b>
                      <StripeDisconnectButton
                        onClick={handleRemoveConnection}
                        disabled={loading}
                      >
                        Прекъснете връзката със Stripe
                      </StripeDisconnectButton>
                    </div>
                  )}
                  {!stripeConnection?.completed_setup && (
                    <div>
                      За да можете да получите вашате печалба, трябва да свържем
                      вашето събитие с акаунт в Stripe.
                      <br />
                      <br />
                      Моля, уверете се, че предоставените данни са точни, защото
                      ще трябва да бъдат верифициране при изтегляне на
                      средствата.
                    </div>
                  )}
                </div>

                {stripeConnection?.completed_setup && (
                  <div className="col-6">
                    <button
                      onClick={handleDashboard}
                      disabled={loading}
                      className={
                        loading ? 'disableBtnStyle w-100 ' : 'input__btn w-100'
                      }
                    >
                      Таблото за управление
                    </button>
                  </div>
                )}

                {!stripeConnection?.completed_setup && (
                  <div className="col-6 ">
                    <div className="singel__input-field mb-15">
                      <label className="input__field-text">
                        Имейл адресът, който ще използвате, за да влезете във
                        вашия акаунт в Stripe:
                      </label>

                      <input
                        type="tetxt"
                        placeholder="Въведете имейл адрес ..."
                        value={stripeEmail}
                        onChange={(e) => {
                          setStripeEmail(e.currentTarget.value)
                        }}
                        required
                      />
                    </div>
                    <button
                      onClick={hanldeAddConnection}
                      disabled={loading}
                      className={
                        loading ? 'disableBtnStyle w-100' : 'input__btn w-100'
                      }
                    >
                      Продължи
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="create__event-area">
              <div className="body__card-wrapper attendant__wrapper">
                <MainInfoHolder>
                  <div>
                    <p style={{fontSize: '1.5rem'}}>
                      Обща печалба: <b>{totalProfit} лв.</b>
                    </p>

                    <p style={{fontSize: '1rem'}}>
                      Приходи от продажба на билети: <b>{ticketSales} лв.</b>
                    </p>
                    <p style={{fontSize: '1rem'}}>
                      Такси: <b>{platformTax} лв.</b>
                    </p>
                  </div>
                </MainInfoHolder>

                <table>
                  <thead>
                    <tr>
                      <th>Тип</th>
                      <th>От</th>
                      <th>Номер</th>
                      <th>Сума</th>
                    </tr>
                  </thead>
                  <tbody>
                    {balance.length === 0 && (
                      <tr>
                        <td colSpan={6}>Няма регистрирани участници</td>
                      </tr>
                    )}
                    {balance &&
                      balance.map((item, index) => (
                        <ListBalance balance={item} index={index} />
                      ))}
                  </tbody>
                </table>

                <PaginationHolder>
                  <Pagination
                    count={totalPages}
                    shape="rounded"
                    page={page}
                    onChange={changePage}
                  />
                </PaginationHolder>
              </div>
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}

const MainInfoHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--clr-border-1);
  padding-bottom: 40px;
  margin-bottom: 20px;
`

const PaginationHolder = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`

const StripeDisconnectButton = styled.button`
  text-align: center;
  padding: 0px 20px;
  display: block;
  color: white;
  background: var(--clr-text-secondary);
  margin-top: 15px;
  border-radius: 4px;
`
